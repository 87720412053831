import React, {useEffect, useRef, useState} from "react";
import dataCarnets from "../../assets/data/dataCarnets.json";
import ImageSkeleton from "../ImageSkeleton/ImageSkeleton";
import {Link} from "react-router-dom";
export default function TimelineMonth(props) {

    const [isTopVisible, setIsTopVisible] = useState(false);
    const [isBottomVisible, setIsBottomVisible] = useState(false);
    const componentRef = useRef(null);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.3,
        };

        /*
        const topObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                setIsTopVisible(entry.isIntersecting);
            });
        }, options);

        const bottomObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                setIsBottomVisible(entry.isIntersecting);
            });
        }, options);

         */

        const handleScrollAndResize = () => {
            const centerY = window.innerHeight / 2;
            const elementCenterY = componentRef.current.getBoundingClientRect().top + componentRef.current.getBoundingClientRect().height / 2;
            const top = componentRef.current.getBoundingClientRect().top
            const bottom = componentRef.current.getBoundingClientRect().bottom
            console.log("top",top)
            console.log("bottom",bottom)
            console.log("centerY",centerY)
            setIsTopVisible(top <= centerY);
            setIsBottomVisible(bottom  <= centerY);
        };

        if (componentRef.current) {
            //topObserver.observe(componentRef.current);
            //bottomObserver.observe(componentRef.current);
        }

        window.addEventListener('scroll', handleScrollAndResize);
        window.addEventListener('resize', handleScrollAndResize);

        return () => {
            if (componentRef.current) {
                //topObserver.unobserve(componentRef.current);
                //bottomObserver.unobserve(componentRef.current);
            }
            window.removeEventListener('scroll', handleScrollAndResize);
            window.removeEventListener('resize', handleScrollAndResize);
        };
    }, []);

    const containerClassName = `timeline-month-container ${isTopVisible || isBottomVisible ? 'timeline-month-container-visible' : ''}`;
    const idStart = dataCarnets.filter(carnet => carnet.date?.M === props.month)[0].id;
    const idEnd = dataCarnets.filter(carnet => carnet.date?.M === props.month)[dataCarnets.filter(carnet => carnet.date?.M === props.month).length-1].id;
    const nbrCarnets = dataCarnets.filter(carnet => carnet.date?.M === props.month).length;

    return(
        <div className={containerClassName} ref={componentRef}  style={{height: nbrCarnets*130+100+"px"}}>

            <div className="timeline-month-title">
                <div className="timeline-month-title-sticky">
                    {props.month}
                </div>
            </div>
            <div className="timeline-range-container">
                <div className="timeline-circle"></div>
            </div>
            <div className="timeline-carnets-container">
                <div className="timeline-month-title-small">
                    {props.month}
                </div>
                {dataCarnets.filter(carnet => carnet.id >= idStart && carnet.id <= idEnd).map (carnet => (
                <Link className="timeline-carnets" to={"/Carnet/" + carnet.id}>
                    <ImageSkeleton imageUrl={carnet.image.front+carnet.format}/>
                    <div className="timeline-carnets-text">
                        <p>{carnet.date.J + " " + carnet.date.M + " • " + carnet.date.A}</p>
                        <p>{carnet.location}</p>
                    </div>
                </Link>
                ))}
            </div>
        </div>
    )
}

/*
<div className="timeline-month-title">
                <div className="timeline-month-title-sticky">
                    Janvier 2022
                </div>
            </div>
            <div className="timeline-range-container">
                <div className="timeline-circle"></div>
            </div>
            <div className="timeline-carnets-container">
                <div className="timeline-month-title-small">
                    Janvier 2022
                </div>
                <div className="timeline-carnets">
                    <img src="https://cordillerice.com/images/Carnets/1/0.webp"/>
                    <div className="timeline-carnets-text">
                        <p>2 Janvier • 2023</p>
                        <p>Torres del Paine</p>
                    </div>
                </div>







<div   style={{color:"white",height:"100%",background:"red"}}>
                {isTopVisible && (
                    <p>Le haut du composant est au milieu de l'écran sur l'axe vertical !</p>
                )}
                {isBottomVisible && (
                    <p>Le bas du composant est au milieu de l'écran sur l'axe vertical !</p>
                )}

</div>
 */