import BtnLink from "./BtnLink";
import "../../styles/components/ContentSectionShared.scss"

export default function ContentSectionShared(props) {

    const containerClass = props.side === "left" ? "content-section-shared-container-left" : "content-section-shared-container-right";


    return (
        <div className={containerClass}>
            <div className="content-section-shared-description-container">
                <div className="content-section-shared-description">
                    <div className="content-section-shared-title-container">
                        <div className="content-section-shared-title">
                            <h1 dangerouslySetInnerHTML={{ __html: props.contentTitle }}></h1>
                        </div>
                    </div>
                    <div className="content-section-shared-content-container">
                        <div className="content-section-shared-content">
                            <p>
                                {props.contentText}
                            </p>
                        </div>
                    </div>
                    <div className="content-section-shared-link-container" style={{display:props.btnText===""?"none":"flex"}}>
                        <BtnLink lien="/Projet" intitule={props.btnText} />
                    </div>
                </div>
            </div>
            <div className="content-section-shared-image-container">
                <div className="content-section-image">
                    <img src={props.image?props.image:"https://cordillerice.com/static/media/instatest.f8cf2e00a58407feb93c.png"} alt="content section image" />
                </div>
            </div>
        </div>
    )
}