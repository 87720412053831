import {useEffect, useRef, useState} from "react";
import TimelineMonth from "./TimelineMonth";

export default function TimelineSection() {

    const [scrollY, setScrollY] = useState(0);
    const timelineRange = {
        background: 'blue',
        height: `${scrollY/2}px`,
        width:'100px'
    }

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <section id="timeline-section-container">
            <TimelineMonth month={'Décembre'} />
            <TimelineMonth month={'Janvier'} />
            <TimelineMonth month={'Février'} />
            <TimelineMonth month={'Mars'} />
            <TimelineMonth month={'Avril'} />
            <TimelineMonth month={'Mai'} />
            <TimelineMonth month={'Juin'} />
        </section>
    );
}