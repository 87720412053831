
export default function TopSectionCarnet() {
    return(
        <section id="top-section-carnet-container">
            <div id="top-section-carnet-background-container">
                <div id="top-section-carnet-title-container">
                    <h1>Carnet de bord</h1>
                    <h2>Suivez-nous au jour le jour !</h2>
                </div>
            </div>
        </section>
    )
}
