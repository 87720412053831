// import "../../styles/pages/CarteV2.scss";
import TopSectionShared from "../../components/Shared/TopSectionShared";
import ContentSectionShared from "../../components/Shared/ContentSectionShared";
import background from "../../assets/images/Projet/BG4.webp";
//import background from "../../assets/images/Projet/08_GlaciarAlercePersonnesCopie.webp";
//import background from "../../assets/images/Projet/02_CampoDeHieloCopy.webp";
import {useTranslation} from "react-i18next";
import img1 from "../../assets/images/Projet/img1.webp";
import img2 from "../../assets/images/Projet/img2.webp";
import img4 from "../../assets/images/Projet/img4.webp";
export default function ProjetV2() {
    const {t} = useTranslation();
    return (
        <main id="ProjetV2">
            <TopSectionShared
                backgroundImage={background}
                titre={t('project.title')}
                sousTitre={t('project.subtitle')}
            />
            <section id="ProjetV2-container">
                <ContentSectionShared
                    side="left"
                    btnText=""
                    contentText={t('project.section1.subtitle')}
                    contentTitle={t('project.section1.title')}
                    image={img2}
                />
                <ContentSectionShared
                    side="right"
                    btnText=""
                    contentText={t('project.section2.subtitle')}
                    contentTitle={t('project.section2.title')}
                    image={img1}
                />
                <ContentSectionShared
                    side="left"
                    btnText=""
                    contentText={t('project.section3.subtitle')}
                    contentTitle={t('project.section3.title')}
                    image={img4}
                />
            </section>
        </main>
    );
}