import React, {useEffect} from "react";
import data from "../../assets/data/dataCarnets.json";
export default function ContentSectionCarnet() {

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    } , [])

    // eslint-disable-next-line no-restricted-globals
    const match = location.pathname.match(/\/Carnet\/(\d+)/);
    const carnetId = match[1];
    const id = parseInt(carnetId) + 1;

    return(
        <section id="content-section-carnet-container">
            <div className="content-section-carnet-img-container">
                {data[id].image.back.map ((img,index) =>(
                    <img className="carnet-img" src={img.url+data[id].format} />
                ))}
            </div>
        </section>
    )
}