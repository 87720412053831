import "../../styles/pages/Contact.scss"
import React, {useContext, useEffect} from "react";
import {t} from "i18next";

import ContactForm from "../../components/ContactForm/ContactForm";

const Contact = () => {

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        }, 400);

    } , [])

    return(

        <main id="Contact">
            <section className="Contact-container">
                <div className="Contact-container-left">
                    <div className="Contact-left">
                        <h2>{t('contact.title')}</h2>
                        <div dangerouslySetInnerHTML={{ __html: t('contact.subtitle') }}></div>
                        <div id="contact-reseaux-container">
                            <a href="https://www.instagram.com/Cordillerice/" className="contact-reseaux-instagram">
                                <span className="contact-reseaux-instagram-text">Instagram</span><br/>
                                <span className="contact-reseaux-nom">@Cordillerice</span>
                            </a>
                            <a href="mailto:cordillerice@proton.me" className="contact-reseaux-email">
                                <span className="contact-reseaux-email-text">Email</span><br/>
                                <span className="contact-reseaux-nom">Cordillerice@proton.me</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="Contact-container-right">
                    <ContactForm/>
                </div>
            </section>
        </main>
    )
}

export default Contact

//<img src={"images/Contact/illustration.webp"} alt="illustration"/>

/*
<section className="bcPages" >
                <div className={"container-bc-top-pages"}></div>
                <div className={"container-title-top-pages"}>
                    <h2>Nous contacter <span style={{padding:"10px"}}>•</span> Nous soutenir</h2>
                    <h1>Contact</h1>
                </div>


            </section>

            <section className={"container-transition-pages"}>
                <div className={"container-transition-top-pages"}></div>
            </section>
 */