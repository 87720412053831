import "../../styles/components/ArticleSmallV2.scss"
import { FaMapMarkerAlt } from "react-icons/fa";
import {CgCalendarDates} from "react-icons/cg";
import {Link} from "react-router-dom";
import dataCarnets from '../../assets/data/dataCarnets.json';
import ImageSkeleton from "../ImageSkeleton/ImageSkeleton";


export default function ArticleSmallV2(props) {

    const skeletonStyle = {
        width: '100%',
        height: '60%',
        position: 'absolute',
        top: 0,
        left: 0,
    }

    const data = dataCarnets;
    const id = props.carnetId + 1;

    function ScrollToTop() {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    }

    return (
        <div className="article-small-V2-container">
            <Link className="article-small-V2" to={"/Carnet/"+props.carnetId} onClick={ScrollToTop}>
                {props.dateM===""?
                    <img className="img-article-small-V2" src="images/Carnets/no-article.jpg" />
                    :<ImageSkeleton imageUrl={data[id].image.front+data[id].format} skeletonStyle={skeletonStyle} imageClass={"img-article-small-V2"}/>
                }
                <div className="descr-article-small-V2">
                    <div><FaMapMarkerAlt className="icon-descr-small"/>{props.location}</div>
                    <div><CgCalendarDates className="icon-descr-small"/>{props.dateJ+" "+props.dateM}{" •"} {props.dateA}</div>
                </div>
            </Link>
        </div>

    );
}



