import "../../styles/pages/PublicationsV2.scss"
import PublicationV2 from "../../components/Publication/PublicationV2";
import dataPubliations from '../../assets/data/dataPublications.json';
import React, {useContext, useEffect} from "react";
import {LanguageContext} from "../../Context/LanguageContext";
import TopSectionShared from "../../components/Shared/TopSectionShared";
import background from "../../assets/images/Publications/BG5.webp";
import {t} from "i18next";

const PublicationsV2 = () => {

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    }, [])


    const data = dataPubliations;
    const {Language} = useContext(LanguageContext);

    return(
        <main id="PublicationsV2">
            <TopSectionShared
                backgroundImage={background}
                titre={t('publications.title')}
                sousTitre={t('publications.subtitle')}
            />

            <section id="all-publications-container">

                {
                    data.slice(0).reverse().map ((Publi) =>(

                        <PublicationV2 images={Publi.images.map(img => img.url+Publi.format)} front={Publi.images[0].url+Publi.format} >
                        </PublicationV2>

                    ))
                }

            </section>
        </main>
    )
}

export default PublicationsV2
