import {Link} from "react-router-dom";
import EastRoundedIcon from '@mui/icons-material/EastRounded';


export default function BtnLink({ intitule, lien }) {
    return (
        <Link className="BtnLink" to={lien}>{intitule}
            <span className="BtnLink-arrow">
              <EastRoundedIcon className="BtnLink-arrow" />
            </span>
        </Link>
    )
}

//→