import "../../styles/pages/CarnetV4.scss";
import { Link } from "react-router-dom";
import TopSectionCarnet from "../../components/SectionCarnet/TopSectionCarnet";
import ContentSectionCarnet from "../../components/SectionCarnet/ContentSectionCarnet";

export default function CarnetV4() {
    return (
        <main id="CarnetV4">
            <TopSectionCarnet />
            <ContentSectionCarnet />
        </main>
    );
}