
import React, {useRef, useState} from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import emailjs from '@emailjs/browser';
import "../../styles/components/ContactForm.scss"



const validationSchema = yup.object({
    email: yup
        .string('Entrez votre email')
        .email('Entrez un email valide')
        .required('Email requis'),
    firstname: yup
        .string('Entrez votre Prénom')
        .min(2, 'Prénom doit être d\'au moins 2 caractères')
        .required('Prénom requis'),
    lastname: yup
        .string('Entrez votre Nom')
        .min(2, 'Nom doit être d\'au moins 2 caractères')
        .required('Nom requis'),
    message: yup
        .string('Entrez votre message')
        .min(2, 'Message doit être d\'au moins 2 caractères')
        .required('Message requis'),
});

const ContactForm = () => {

    const form = useRef();

    const [isSend, setSend] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: '',
            firstname: '',
            message: '',
            lastname: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setSend(true);

            emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_PUBLIC_KEY)
                .then((result) => {
                    setSend(true);
                    console.log(result.text);
                    setSend(true);
                }, (error) => {
                    setSend(true);
                    console.log(error.text);
                    setSend(true);
                });
        },
    });


    return (
        <div id="contact-form-container">
            <form onSubmit={formik.handleSubmit}  ref={form}>
                <TextField
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={(formik.touched.email && formik.errors.email)?formik.touched.email && formik.errors.email:" "}
                />

                <TextField
                    margin="dense"
                    fullWidth
                    id="firstname"
                    name="firstname"
                    label="Prénom"
                    value={formik.values.firstname}
                    onChange={formik.handleChange}
                    error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                    helperText={(formik.touched.firstname && formik.errors.firstname)?formik.touched.firstname && formik.errors.firstname:" " }
                />
                <TextField
                    margin="dense"
                    fullWidth
                    id="lastname"
                    name="lastname"
                    label="Nom"
                    value={formik.values.lastname}
                    onChange={formik.handleChange}
                    error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                    helperText={(formik.touched.lastname && formik.errors.lastname)?formik.touched.lastname && formik.errors.lastname:" " }
                />

                <TextField
                    placeholder=""
                    fullWidth
                    multiline
                    name="message"
                    label="Message"
                    rows={3}
                    maxRows={3}
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    error={formik.touched.message && Boolean(formik.errors.message)}
                    helperText={(formik.touched.message && formik.errors.message)?formik.touched.message && formik.errors.message:" " }

                />

                <Button
                    id="contact-form-button"
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    style={{marginTop: "20px"}}
                    disableElevation>
                    Envoyer
                </Button>
                <div id="response-form" style={{display:isSend?"block":"none"}} >
                    {isSend && <span>Votre message a bien été envoyé !</span>}
                </div>
            </form>
        </div>
    );
};

export default ContactForm;

//alert(JSON.stringify(values, null, 2));
//onSubmit={formik.handleSubmit}