
import { useTranslation, initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import resources from "./resources.js";
import i18n from "i18next";

i18n
    .use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: resources,
        fallbackLng: "fr",
        debug: true,
    interpolation: {
        escapeValue: false // react already safes from xss
    }
});

export default i18n;