import ArticleSmall from "../ArticleSmall/ArticleSmall";
import {useState} from "react";
import dataCarnets from "../../assets/data/dataCarnets.json";
import ArticleSmallV2 from "../ArticleSmall/ArticleSmallV2";
import BtnLink from "../Shared/BtnLink";
import {t} from "i18next";


export default function CarnetHomeSection() {
    const [Language, setLanguage] = useState("FR");
    const CarnetsLimited = dataCarnets.slice(-3);

    return(
        <section id="carnet-article-home-container">
            <article>
                <div>
                    <h1>
                        {t('home.CarnetSection.title')}
                    </h1>
                    <h2>
                        {t('home.CarnetSection.subtitle')}
                    </h2>
                </div>
                <div>
                    {CarnetsLimited.map (carnet =>(
                        <ArticleSmallV2 carnetId={carnet.id} location={carnet.location} dateJ={carnet.date.J} dateM={carnet.date.M} dateA={carnet.date.A}>
                        </ArticleSmallV2>
                    ))}
                </div>
            </article>
            <div id="link-carnet-home">
                <BtnLink lien="/Carnets" intitule={t('home.CarnetSection.link')}
                />
            </div>
        </section>
    )
}