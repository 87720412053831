import "../../styles/pages/CarteV2.scss";
import TopSectionShared from "../../components/Shared/TopSectionShared";
import {Skeleton} from "@mui/material";
import React, {useState} from "react";
import background from "../../assets/images/Publications/BG3.webp";
import {t} from "i18next";
export default function CarteV2() {

    const [isLoading, setIsLoading] = useState(true);
    function handleIframeLoad() {
        setTimeout(() => {
            setIsLoading(false);
        }, 400);

    }
    return (
        <main id="CarteV2">
            <TopSectionShared
                backgroundImage={background}
                titre={t('map.title')}
                sousTitre={t('map.subtitle')}
            />
            <section id="CarteV2-container">
                <div className="iframe-container">
                    {isLoading && (
                        <div className="iframe-skeleton">
                            <Skeleton id="iframe-skeleton-1" variant="rectangular"  width={210} height={118} />
                        </div>
                    )}
                    <iframe id="iframe"  frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"

                            title="Trajet AMSUR"
                            src="//cordilleras.maps.arcgis.com/apps/Embed/index.html?center=270,-30&level=4&webmap=79e0e54682b54b2989393507fa205907&extent=-180,-84.0889,180,84.2319&zoom=true&previewImage=false&scale=true&disable_scroll=true&theme=light"
                            onLoad={handleIframeLoad}
                            style={{ display: isLoading ? 'none' : 'flex' }}
                    ></iframe>
                </div>
            </section>
        </main>
    );
}