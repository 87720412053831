import "../../styles/pages/homeV2.scss"
import psl from "../../assets/images/home/logo/psl.webp";
import ey from "../../assets/images/home/logo/ey.webp";
import parismie from "../../assets/images/home/logo/parismie.webp";
import nouvelleaquitaine from "../../assets/images/home/logo/nouvelleaquitaine.webp";
import fondationENG from "../../assets/images/home/logo/fondationENG.webp";
import cimeo from "../../assets/images/home/logo/cimeo.webp";
import aulm from "../../assets/images/home/logo/aulm.webp";
import agro from "../../assets/images/home/logo/agro.webp";
import {t} from "i18next";
export default function PartenaireSection() {
    return(
        <section id="partenaire-section-container">
            <div id="partenaire-section-title-container">
                <div id="partenaire-section-title">
                    <h1>{t('home.PartenaireSection.title')}</h1>
                </div>
            </div>
            <div id="partenaire-section-content-container">
                <div className="partenaire-section-logo">
                    <img src={psl} />
                </div>
                <div className="partenaire-section-logo">
                    <img src={agro} />
                </div>
                <div className="partenaire-section-logo">
                    <img src={cimeo} />
                </div>
                <div className="partenaire-section-logo">
                    <img src={nouvelleaquitaine} />
                </div>
                <div className="partenaire-section-logo">
                    <img src={fondationENG} />
                </div>
                <div className="partenaire-section-logo">
                    <img src={parismie} />
                </div>
                <div className="partenaire-section-logo">
                    <img src={aulm} />
                </div>
                <div className="partenaire-section-logo">
                    <img src={ey} />
                </div>
            </div>
        </section>
    )
}