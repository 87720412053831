import HeaderV2 from "./components/Header/HeaderV2";
import { Route, Routes } from "react-router-dom";
import HomeV2 from "./pages/Home/HomeV2";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import Footer from "./components/Footer/Footer";
import CarnetsV2 from "./pages/Carnets/CarnetsV2";
import ProjetV2 from "./pages/Projet/ProjetV2";
import Contact from "./pages/Contact/Contact";
import CarteV2 from "./pages/Carte/CarteV2";
import CarnetV4 from "./pages/Carnet/CarnetV4";
import PublicationsV2 from "./pages/Publications/PublicationsV2";


const App = () => {


    return(

        <div id="app">
            <HeaderV2 />
            <Routes>
                <Route exact path="/" element={<HomeV2 />}  />
                <Route exact path="/page1" element={<HomeV2/>} />
                <Route exact path="/Carnets" element={<CarnetsV2/>} />
                <Route exact path="/Publications" element={<PublicationsV2/>} />
                <Route exact path="/Carnet/:id" element={<CarnetV4/>} />
                <Route exact path="/Projet" element={<ProjetV2/>} />
                <Route path="/*" element={<ErrorPage errorMessage="Error 404"/>} />
                <Route path="/Contact" element={<Contact/>} />
                <Route path="/Carte" element={<CarteV2/>} />
            </Routes>

            <Footer />
        </div>

    )
}

export default App