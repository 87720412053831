import "../../styles/components/TopSectionShared.scss";

export default function TopSectionShared(props) {

    const backgroundPage = {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '0 70%',
        backgroundImage: `url(${props.backgroundImage})`,
    }

    return(
        <section id="top-section-shared-container" style={backgroundPage}>
            <div id="top-section-shared-title-container">
                <div id="top-section-shared-title">
                    <h1>{props.titre}</h1>
                    <h2 dangerouslySetInnerHTML={{ __html: props.sousTitre }}></h2>
                </div>
            </div>
        </section>
    )
}

