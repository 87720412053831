import EquipeSectionV2 from "../components/SectionHome/EquipeSectionV2";

const resources  = {
    en: {
        translation: {
            home: {
                topSection: {
                    subtitle: "Explore • Study • Raise awareness",
                },
                ProjectSection: {
                    title: "The Project",
                    subtitle1: "CordillerICE is a scientific, sports and educational student project.",
                    subtitle2: "Passionate about mountains and curious to discover new ones, the team members are going to study the melting of the Andes glaciers and its various impacts",
                    content: {
                        title1:"A committed team",
                        subtitle1:"We are 4 science students who wish to contribute their knowledge",
                        title2:"A journey through a continent",
                        subtitle2:"6,500 km traveled over more than 45° of latitude, the equivalent of a round trip Lisbon-Helsinki!",
                        title3:"Working with scientists",
                        subtitle3:"Exchange with South American glaciologists, hydrologists and ecologists in 6 laboratories",
                        title4:"An awareness-raising work",
                        subtitle4:"Weekly support and exchanges with 10 classes, from primary to high school"
                    },
                    button: "Learn more"
                },
                PartenaireSection: {
                    title: "Our Partners",
                },
                contentSection1: {
                    title: "Find our publications •<br/> From Instagram",
                    subtitle: "Follow our adventure through our Instagram account. We regularly publish photos and videos of our daily life. You will be able to follow our progress and discover the landscapes we cross over the weeks!",
                    link: "See our account",
                },
                EquipeSection: {
                    title: "Four students •<br/> One team",
                    subtitle: "We are four science students passionate about mountains, interested in glaciology, concerned about the environmental crisis and above all curious! Coming from four different schools, we have joined forces to carry out this project and combine our skills and knowledge",
                    content: {
                        title1:"I travel to observe",
                        subtitle1:"For me, this project is an opportunity to explore unique glacial environments, and to contemplate the nature that has made these hostile places its own. I also hope to develop my knowledge by learning techniques from scientists and local players. I'm keen to report this knowledge in detail, so as to highlight the richness of my observations",
                        title2:"I travel to learn",
                        subtitle2:"Having hiked for years in the mountains, I have developed an affection for these giants of stone and ice. I want to protect them through my work, which requires first of all to understand them beyond what courses could bring me. For this, I wish to learn from scientists and mountain people to take the full measure of the richness of these environments and societies",
                        title3:"I travel to exchange",
                        subtitle3:"The encounters I have made through past experiences, over the course of my years of study or along a path, have all contributed to shaping my worldview. During this trip, exchanging will be the best way for me to open up to others and discover lifestyles different from mine. I want to listen to the needs of some and receive advice from others, to take one more step into the mountain environment, glaciology being a science that I only know through readings or documentaries",
                        title4:"I travel to transmit",
                        subtitle4:"I love sharing my love of science, and I'm planning to go into teaching. So I'm joining the project to help people discover the daily lives of researchers, raise awareness of global warming and encourage girls to take up careers in science. I spent my childhood in the mountains, so the mountains of Grenoble hold almost no secrets for me. I can't miss the opportunity to discover mountains further afield!"
                    }
                },
                CarnetSection: {
                    title: "The Weekly Logbooks",
                    subtitle: "Follow the weekly exchanges with the classes",
                    link: "See more"
                },
                ContactSection: {
                    title: "Do you want to contact us?",
                    subtitle: "Send us a message!",
                    link1: "Contact",
                    link2: "Instagram"
                }
            },
            header: {
                home: "Home",
                ourProject: "Our Project",
                logBooks: "Logbooks",
                publications: "Publications",
                map: "Map",
                contact: "Contact",
            },
            project: {
                title: "The objectives of our project",
                subtitle: "CordillerICE is a scientific and educational student project. Passionate about mountains and curious to discover new ones, the team members are going to study the melting of the Andes glaciers and its various impacts",
                section1:{
                    title:"Explore",
                    subtitle:"From December 2022 to July 2023, we will go up the Andes mountain range from Patagonia to the tropics. Each stage will be an opportunity for us to explore the mountains, camp and discover the Andean glaciers"
                },
                section2:{
                    title:"Study",
                    subtitle:"From laboratory to laboratory, we will help South American glaciologists to take measurements on glaciers and process data. Each expedition will be an opportunity for us to learn more about the work of these little-known scientists, and to exchange with them about the consequences of glacier melting"
                },
                section3:{
                    title:"Raise awareness",
                    subtitle:"We want our project to benefit as many people as possible. During our trip, we will exchange with French schoolchildren in order to contribute to their awareness of the environmental crisis"
                }
            },
            logBooks: {
                title: "Logbooks",
                subtitle: "Find the news of the week since the beginning of our adventure!"
            },
            publications: {
                title: "Publications",
                subtitle: "Find all our publications since the beginning of our adventure!"
            },
            map: {
                title: "The map of our expedition",
                subtitle: "Find all the stages of our adventure! <br/> Click on the points to discover the logbooks of the corresponding week"
            },
            contact: {
                title: "Do you want to contact us?",
                subtitle: "Do you have a question, a suggestion?<br/>" +
                    "Do not hesitate to contact us via the email address below or via the contact form.<br/>" +
                    "You can also follow us on social networks!"
            }
        }
    },
    fr: {
        translation: {
            home: {
                topSection: {
                    subtitle: "Explorer • Etudier • Sensibiliser",
                },
                ProjectSection: {
                    title: "Le Projet",
                    subtitle1: "CordillerICE est un projet étudiant scientifique, sportif et éducatif.",
                    subtitle2: "Passionné·es de montagnes et curieux·ses d’en découvrir de nouvelles, les membres de l'équipe partent étudier la fonte des glaciers des Andes et ses divers impacts",
                    content: {
                        title1:"Une équipe engagée",
                        subtitle1:"Nous sommes 4 étudiant·es en sciences qui souhaitent mettre leur connaissances à contribution",
                        title2:"Un périple à travers un continent",
                        subtitle2:"6 500 km parcourus sur plus de 45° de latitude, l’équivalent d’un aller-retour Lisbonne-Helsinki!",
                        title3:"Travail avec des scientifiques",
                        subtitle3:"Échange avec des glaciologues, hydrologues et écologues dans 6 laboratoires",
                        title4:"Un travail de sensibilisation",
                        subtitle4:"Accompagnement et échanges hebdomadaires de 10 classes, du primaire au lycée"
                    },
                    button: "En savoir plus"
                },
                PartenaireSection: {
                    title: "Nos Partenaires",
                },
                contentSection1: {
                    title: "Retrouvez nos publications •<br/> Depuis Instagram",
                    subtitle: "Suivez notre aventure à travers notre compte Instagram. Nous publions régulièrement des photos et des vidéos de notre quotidien. Vous pourrez ainsi suivre notre progression et découvrir les paysages que nous traversons au fil des semaines!",
                    link: "Voir notre compte",
                },
                EquipeSection: {
                    title: "Quatre étudiant·es •<br/> Une équipe",
                    subtitle: "Nous sommes quatre étudiant·es en science passionné·es de montagne, intéressé·es par la glaciologie, concerné·es par la crise environnementale et avant tout curieux·ses! Provenant de quatre écoles différentes, nous nous sommes associé·es pour mener à bien ce projet et combiner nos compétences et connaissances",
                    content: {
                        title1:"Je pars pour observer",
                        subtitle1:"Ce projet, c'est pour moi l'opportunité d'explorer des environnements glaciaires tous uniques, et de contempler la nature qui s'est approprié ces endroits pourtant hostiles. J'aspire également à développer mes connaissances en apprenant les techniques des scientifiques et des acteurs locaux. Il me tient à cœur de rapporter ces savoirs avec détail afin de mettre en valeur la richesse de ces observations",
                        title2:"Je pars pour apprendre",
                        subtitle2:"Ayant randonné pendant des années en montagne, j’ai développé une affection pour ces géantes de pierre et de glace. Je veux les protéger à travers mon travail, ce qui nécessite avant tout de les comprendre au-delà de ce que des cours ont pu m’apporter. Pour cela, je souhaite apprendre auprès de scientifiques et des montagnard.e.s pour prendre la pleine mesure de la richesse de ces environnements et sociétés",
                        title3:"Je pars pour échanger",
                        subtitle3:"Les rencontres que j’ai pu faire par le biais d’expériences passées, au fil de mes années d’études ou au détour d’un chemin, ont toutes contribué à forger ma vision du monde. Lors de ce voyage, échanger sera pour moi la meilleure manière de m’ouvrir aux autres et de découvrir des modes de vie différents du mien. Je souhaite écouter les besoins des un.e.s et recevoir les conseils des autres, pour mettre un pas de plus dans le milieu montagnard, la glaciologie étant une science que je ne connais que par le biais de lectures ou documentaires",
                        title4:"Je pars pour transmettre",
                        subtitle4:"J’aime partager mon goût pour les sciences et j’envisage de mon consacrer à l’enseignement. Je rejoins donc le projet pour faire découvrir le quotidien des chercheurs, sensibiliser au réchauffement climatique, encourager les filles aux métiers de la science. J’ai passé mon enfance en montagne, les massifs grenoblois n’ont presque plus de secrets pour moi. Je ne peux pas rater l’occasion de découvrir des montagnes plus éloignées!"
                    }
                },
                CarnetSection: {
                    title: "Les Carnets de bord de la semaine",
                    subtitle: "Suivez les échanges hebdomadaires avec les classes",
                    link: "Voir plus"
                },
                ContactSection: {
                    title: "Vous souhaitez nous contacter?",
                    subtitle: "Envoyez-nous un message!",
                    link1: "Contact",
                    link2: "Instagram"
                }
            },
            header: {
                home: "Accueil",
                ourProject: "Notre Projet",
                logBooks: "Carnets de bord",
                publications: "Publications",
                map: "Carte",
                contact: "Contact",
            },
            project: {
                title: "Les objectifs de notre projet",
                subtitle: "CordillerICE est un projet étudiant scientifique, sportif et éducatif",
                section1:{
                    title:"Explorer",
                    subtitle:"De décembre 2022 à juillet 2023, nous remonterons la cordillère des Andes de la Patagonie jusqu' aux tropiques. Chaque étape sera pour nous l'occasion d'explorer les montagnes, de camper et de découvrir les glaciers andins"
                },
                section2:{
                    title:"Etudier",
                    subtitle:"De laboratoire en laboratoire, nous viendrons en aide aux glaciologues sud-américain.e.s pour effectuer des mesures sur glaciers et traiter les données. Chaque expédition sera pour nous l'occasion d'en apprendre un peu plus sur le travail de ces scientifiques méconnu.e.s, et d'échanger avec elleux autour des conséquences de la fonte des glaciers"
                },
                section3:{
                    title:"Sensibiliser",
                    subtitle:"Nous souhaitons que notre projet puisse profiter à autant de personnes que possible. Au cours de notre voyage, nous échangerons avec des écoliers, collégiens et lycéens français afin de contribuer à les sensibiliser à la crise environnementale"
                }
            },
            logBooks: {
                title: "Carnets de bord",
                subtitle: "Retrouvez les nouvelles de la semaine depuis le commencement de notre aventure!"
            },
            publications: {
                title: "Publications",
                subtitle: "Retrouvez toutes nos publications depuis le commencement de notre aventure !"
            },
            map: {
                title: "La carte de notre expédition",
                subtitle: "Retrouvez toutes les étapes de notre aventure !<br/>" +
                    "Cliquez sur les points pour découvrir les carnets de bord de la semaine correspondante"
            },
            contact: {
                title: "Vous souhaitez nous contacter ?",
                subtitle: "Vous avez une question, une suggestion ?<br/>" +
                    "N'hésitez pas à nous contacter via l'adresse mail ci-dessous ou via le formulaire de contact.<br/>" +
                    "Vous pouvez également nous suivre sur les réseaux sociaux !"
            }
        }
    }
};

export default resources;
