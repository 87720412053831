// ImageLazyLoad.jsx

import React, { useRef, useEffect, useState } from 'react';
import useIntersectionObserver from '../../hook/useIntersectionObserver';


const _loaded = {};

const ImageLazyLoad = ({ className, url, alt, placeholder = null, forcePreloadImage = false }) => {
    const elementRef = useRef(null);
    const [inView] = useIntersectionObserver(elementRef);
    const [loaded, setLoaded] = useState(_loaded[url]);

    useEffect(() => {
        if (inView) {
            if (_loaded[url]) {
                setLoaded(true);

                return;
            }

            const img = new Image();
            img.src = url;
            img.onload = () => {
                _loaded[url] = true;
                setLoaded(true);
            };
        }
    }, [inView]);

    return (
        <div ref={elementRef} className={className ? `${className}-wrapper` : null}>
            {!loaded ? (
                placeholder ? (
                    placeholder
                ) : null
            ) : (
                <img src={url} className={className} alt={alt} />
            )}
            <noscript>
                <img src={url} className={className} alt={alt} />
            </noscript>
        </div>
    );
};

export default ImageLazyLoad;
