import React, {useEffect, useState} from 'react';
import FsLightbox from 'fslightbox-react';
import "../../styles/components/PublicationV2.scss"
import ImageLazyLoad from "../ImageLazyLoad/ImageLazyLoad";
import {Skeleton} from "@mui/material";

function PublicationV2(props) {

    const [toggler, setToggler] = useState(false);

    return (
        <article className="unique-publication-container">
            <div className="play-button-container" onClick={() => setToggler(!toggler)}></div>

            <ImageLazyLoad
                className="img-publication"
                url={props.front}
                alt={'alt'}
                onClick={() => setToggler(!toggler)}
                placeholder={<Skeleton variant="rectangular"  width={280} height={280} animation="wave" />}
            />

            <FsLightbox
                toggler={toggler}
                sources={props.images}
            />

        </article>
    );
}

export default PublicationV2;
