import "../../styles/pages/homeV2.scss"
import background from "../../assets/images/home/Bariloche_V3.webp"
import { useTranslation} from "react-i18next";

const TopSection = () => {
    const {t} = useTranslation();
    return(
        <section id="top-section-container">
            <div id="top-section-title-container" style={{backgroundImage:`url(${background})`}}>
                <div id="top-section-title">
                    <h1>Cordillerice</h1>
                    <h2>{t('home.topSection.subtitle')}</h2>
                </div>
                <div id="top-section-video-container">
                    <div id="top-section-video">
                        <div id="top-section-video-player">

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TopSection;


/*
 <div id="top-section-title-container" style={backgroundImage=`url(${backgroundImage})`}>

 <video width="1000" height="600" autoPlay loop>
                                <source src="/video/panorama.mp4" type="video/mp4"/>
                            </video>
                            */
