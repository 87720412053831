import {useState} from "react";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import ScienceOutlinedIcon from "@mui/icons-material/ScienceOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import BtnLink from "../Shared/BtnLink";
import {useTranslation} from "react-i18next";
import {t} from "i18next";

export default function ProjectSection() {

    const [Language, setLanguage] = useState("FR");

    return (
        <section id="project-section-container">
            <h1>{t('home.ProjectSection.title')}</h1>
            <h2>
                {t('home.ProjectSection.subtitle1')}<br/>
                {t('home.ProjectSection.subtitle2')}
            </h2>

            <div id="project-objectives-container">
                <article className="projet-icon-container">
                    <div>
                        <HandshakeOutlinedIcon className='projet-icon' />
                    </div>
                    <div>
                        <h3> {t('home.ProjectSection.content.title1')}</h3>
                        {t('home.ProjectSection.content.subtitle1')}
                    </div>
                </article>
                <article className="projet-icon-container">
                    <div>
                        <LanguageOutlinedIcon className='projet-icon' />
                    </div>
                    <div>
                        <h3>{t('home.ProjectSection.content.title2')}</h3>
                        {t('home.ProjectSection.content.subtitle2')}
                    </div>
                </article>
                <article className="projet-icon-container">
                    <div>
                        <ScienceOutlinedIcon className='projet-icon' />
                    </div>
                    <div>
                        <h3>{t('home.ProjectSection.content.title3')}</h3>
                        {t('home.ProjectSection.content.subtitle3')}
                    </div>
                </article>
                <article className="projet-icon-container">
                    <div>
                        <CampaignOutlinedIcon className='projet-icon' />
                    </div>
                    <div>
                        <h3>{t('home.ProjectSection.content.title4')}</h3>
                        {t('home.ProjectSection.content.subtitle4')}
                    </div>
                </article>
            </div>
            <div>
                <BtnLink lien="/Projet" intitule={t('home.ProjectSection.button')}/>
            </div>

        </section>
    )
}