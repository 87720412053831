import "../../styles/pages/errorPage.scss"

const ErrorPage = (props) => {

    return(
        <div>
            <h1>An error has occured : { props.errorMessage }</h1>
        </div>
    )
}

export default ErrorPage