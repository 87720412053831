
import {createContext, useState} from 'react';

export const LanguageContext = createContext( {Language: "FR", undefined})

export const LanguageProvider = ({children}) => {
    const [Language,setLanguage] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("Language");
        const initialValue = JSON.parse(saved);
        // Setting a base value in case someone messed with localStorage value
        if(initialValue!=="FR" && initialValue!=="EN"){
            return "FR"
        }
        return initialValue || "FR";
    })

    return(
        <LanguageContext.Provider value={{Language,setLanguage}}>
            {children}
        </LanguageContext.Provider>
    )

}
