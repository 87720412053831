import {FaFlask, FaMapMarkerAlt, FaSchool} from "react-icons/fa";
import Rarrow from "../../assets/images/header/right-arrow-dark.png";
import {useState} from "react";
import {t} from "i18next";

export default function EquipeSectionV2() {
    const [Language, setLanguage] = useState("FR");
    const showDescrEkip = event => {

        const DescrOpen = document.querySelector('.descr-open');

        if (DescrOpen != null) {
            if (DescrOpen === event.currentTarget.parentNode) {
                event.currentTarget.parentNode.classList.toggle('descr-open');
            }
            else {
                DescrOpen.classList.remove('descr-open');
                event.currentTarget.parentNode.classList.toggle('descr-open');
            }
        }
        else {
            event.currentTarget.parentNode.classList.toggle('descr-open');
        }
    };
    return (
    <section id="equipe-sectionV2-container">
        <article>
            <h1 dangerouslySetInnerHTML={{__html:t('home.EquipeSection.title')}}></h1>
            <h2>
                {t('home.EquipeSection.subtitle')}<br/>
            </h2>
        </article>
        <article>
            <div className="col-member">
            <div className="container-descr">
                    <div className="descr-picture">
                        <div className="img-descr"></div>
                    </div>
                    <div className="descr-details">
                        <h3>Maud Bernat</h3>
                        <div>
                            <div><FaMapMarkerAlt />La Rochelle</div>
                            <div><FaSchool />Ecole des Mines</div>
                            <div><FaFlask />{Language === "FR"?"Glaciologie":"Glaciology"}</div>
                        </div>
                    </div>
                <div className="descr-infos">
                        <span className="descr-infos-fs">
                            {t('home.EquipeSection.content.title1')}
                        </span><br/><br/>
                    <div>
                        {t('home.EquipeSection.content.subtitle1')}
                    </div>
                    <img src={Rarrow} onClick={showDescrEkip}/>
                </div>
            </div>
                <div className="container-descr">
                    <div className="descr-picture">
                        <div className="img-descr"></div>
                    </div>
                    <div className="descr-details">
                        <h3>Gaston Bidou</h3>
                        <div>
                            <div><FaMapMarkerAlt />Paris</div>
                            <div><FaSchool />Ecole Polytechnique</div>
                            <div><FaFlask />{Language === "FR"?"Hydrodynamique numérique":"Computational fluid dynamics"}</div>
                        </div>
                    </div>
                    <div className="descr-infos">
                        <span className="descr-infos-fs">
                            {t('home.EquipeSection.content.title2')}
                        </span><br/><br/>
                        <div>
                            {t('home.EquipeSection.content.subtitle2')}
                        </div>
                        <img src={Rarrow} onClick={showDescrEkip}/>
                    </div>
                </div>
            </div>
            <div className="col-member">
                <div className="container-descr">
                    <div className="descr-picture">
                        <div className="img-descr"></div>
                    </div>
                    <div className="descr-details">
                        <h3>Emma Denise</h3>
                        <div>
                            <div><FaMapMarkerAlt />Goulven, Finistère</div>
                            <div><FaSchool />AgroParisTech</div>
                            <div><FaFlask />{Language === "FR"?"Environnement forestier":"Forest environment"}</div>
                        </div>
                    </div>
                    <div className="descr-infos">
                        <span className="descr-infos-fs">
                            {t('home.EquipeSection.content.title3')}
                        </span><br/><br/>
                        <div>
                            {t('home.EquipeSection.content.subtitle3')}
                        </div>
                        <img src={Rarrow} onClick={showDescrEkip}/>
                    </div>
                </div>
                <div className="container-descr">
                    <div className="descr-picture">
                        <div className="img-descr"></div>
                    </div>
                    <div className="descr-details">
                        <h3>Domitille Dufour</h3>
                        <div>
                            <div><FaMapMarkerAlt />Grenoble</div>
                            <div><FaSchool />ENS Ulm</div>
                            <div><FaFlask />{Language === "FR"?"Géosciences":"Geosciences"}</div>
                        </div>
                    </div>
                    <div className="descr-infos">
                        <span className="descr-infos-fs">
                            {t('home.EquipeSection.content.title4')}
                        </span><br/><br/>
                        <div>
                            {t('home.EquipeSection.content.subtitle4')}
                        </div>
                        <img src={Rarrow} onClick={showDescrEkip}/>
                    </div>
                </div>
            </div>
        </article>
    </section>
    )
}