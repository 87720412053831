import "../../styles/pages/homeV2.scss"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useState} from "react";
import TopSection from "../../components/SectionHome/TopSection";
import PartenaireSection from "../../components/SectionHome/PartenaireSection";
import EquipeSectionV2 from "../../components/SectionHome/EquipeSectionV2";
import ProjectSection from "../../components/SectionHome/ProjectSection";
import CarnetHomeSection from "../../components/SectionHome/CarnetHomeSection";
import ContactSection from "../../components/SectionHome/ContactSection";
import ContentSectionShared from "../../components/Shared/ContentSectionShared";
import imgInstagram from "../../assets/images/home/instatest.png";
import {useTranslation} from "react-i18next";

const HomeV2 = () => {

    const { t, i18n } = useTranslation();
    const [loadedImagesCount, setLoadedImagesCount] = useState(0);
    const handleImageLoaded = () => {
        setLoadedImagesCount(prevCount => prevCount + 1);
    }

    return(
        <main>
            <TopSection/>
            <ProjectSection/>
            <PartenaireSection/>
            <ContentSectionShared side="left" contentTitle={t('home.contentSection1.title')} contentText={t('home.contentSection1.subtitle')} btnText={t('home.contentSection1.link')} image={imgInstagram} />
            <EquipeSectionV2/>
            <CarnetHomeSection/>
            <ContactSection/>
        </main>
    )
}

export default HomeV2
