import "../../styles/components/headerV2.scss"
import {useContext, useEffect, useState} from "react";
import {Link, useLocation} from 'react-router-dom';
import { Squash as Hamburger } from 'hamburger-react';
import useMediaQuery from '@mui/material/useMediaQuery';
import FRflag from '../../assets/images/header/france.png';
import ENflag from '../../assets/images/header/united-kingdom.png';
import RarrowDark from '../../assets/images/header/right-arrow-dark.png';
import RarrowWhite from '../../assets/images/header/right-arrow-light.png';
import LogoSite from '../../assets/images/header/LogoSite.png';
import '@mui/material';
import { ClickAwayListener } from '@mui/base';
import {useTranslation} from "react-i18next";
import {changeLanguage, t} from "i18next";
function HeaderV2() {

    const nameUrl = window.location.href;
    const [isOpen, setOpen] = useState(false);
    const [navShape, setShape] = useState("navNormal");
    const [selectionLanguage, setselectionLanguage] = useState(false);
    let matches1200 = useMediaQuery('(max-width:1200px)');
    let matches1600 = useMediaQuery('(max-width:1600px)');
    const [isLinkHover, setLinkHover] = useState(false);
    const [shapeLinkHover,setShapeLink] = useState("Link-Body-Container-hide");
    const [scrolling, setScrolling] = useState(false);
    const [isScrolled80, setIsScrolled80] = useState(false);
    const location = useLocation();
    const isOnPageLight = location.pathname === '/Contact';

    useEffect(() => {
        if (matches1200) {
            setOpen(false);
            setShape("navHamburger")
        } else {
            setShape("navNormal")
        }
    }, [matches1200]);


    const NavShape = () => {
        if (!isOpen) {
            setShape("navHamburgerOpen");
            setOpen(true);
        } else {
            setShape("navHamburgerClose");
            setOpen(false);
        }
    };

    const showBody = () => {
        if(!isLinkHover) {
            setLinkHover(true);
            setShapeLink("Link-Body-Container-show")
        }
    };

    const hideBody = () => {
        if(isLinkHover) {
            setLinkHover(false);
            setShapeLink("Link-Body-Container-hide")
        }
    };

    const showLanguage = () => {
        if(!selectionLanguage) {
            setselectionLanguage(true);
        }
        else {
            setselectionLanguage(false);
        }
    };


    function switchLanguageEN() {
        changeLanguage("en")
        ScrollToTop()
        showLanguage()
    }

    function switchLanguageFR() {
        changeLanguage("fr")
        ScrollToTop()
        showLanguage()
    }

    function ScrollToTop() {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    }

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolling(true);
            } else {
                setScrolling(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            const viewportHeight = window.innerHeight;

            if (scrollY > viewportHeight * 0.8) {
                setIsScrolled80(true);
            } else {
                setIsScrolled80(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const containerClassName = `my-container ${isScrolled80 ? 'scrolled' : ''}`;

    const language = useTranslation().i18n.language;
    console.log('lng : '+ language);

    return nameUrl.includes("LoginSuccess")? null : (

        <header hamburger={matches1200} className={`secondary ${scrolling ? 'scrolled' : ''} ${isScrolled80?'headerDark' : ''}  ${isOnPageLight?'headerLight' : ''}`} >
            {matches1200?null:<div className="EmptyContainerBis" />}
            <div className="LinkContainer">
                <img src={LogoSite} />
            </div>

            <nav className={`${navShape}`}>
                {matches1600?null:<div className="EmptyContainer" />}

                <div id="wrapper-header-link">

                <div className="LinkContainer">
                    <Link  to="/" className="text" onClick={ScrollToTop} >{t('header.home')}</Link>
                </div>

                <div className="LinkContainer">
                    <Link to="/Projet" className="text" onClick={ScrollToTop}>{t('header.ourProject')}</Link>
                </div>
                <div className="LinkContainer">
                    <Link onMouseOver={showBody} onMouseOut={hideBody} onClick={ScrollToTop}
                          to="/Carnets" className="text">{t('header.logBooks')}
                    </Link>
                </div>

                <div id="body-Container1" className={shapeLinkHover}>
                    <div id="left-body-Container1">

                    </div>
                    <div id="right-body-Container1">
                        <div>
                            <a></a>
                            <a></a>
                            <a></a>
                        </div>
                        <div></div>
                    </div>
                </div>

                <div className="LinkContainer">
                    <Link to="/Publications" className="text" onClick={ScrollToTop}>{t('header.publications')}</Link>
                </div>
                <div className="LinkContainer">
                    <Link to="/Carte" className="text">{t('header.map')}</Link>
                </div>
                <div className="LinkContainer">
                    <Link to="/Contact" className="text">{t('header.contact')}</Link>
                </div>
                </div>

                {matches1600?null:<div className="EmptyContainer" />}


            </nav>

            <div className="LinkContainer">
                <div id="language-container" onClick={showLanguage} ><img src={language === "fr"? FRflag : ENflag} />
                    <button >
                        <img className={selectionLanguage? 'open-Arrow': null} src={isOnPageLight?RarrowDark:RarrowWhite}/>
                    </button>
                </div>
                {selectionLanguage?
                    <ClickAwayListener onClickAway={showLanguage}>
                        <div id="switch-language-container" >
                            <button  onClick={switchLanguageFR} className={language === "fr"? 'selected-language': null}>
                                <img  src={FRflag} />FR
                            </button>
                            <button onClick={switchLanguageEN}  >
                                <img src={ENflag}/> EN</button>
                        </div>
                    </ClickAwayListener >
                    :null}

            </div>

            {matches1200?<div className="EmptyContainer" />:null}
            {matches1200?<div className="LinkHamburger"><Hamburger rounded toggled={isOpen} toggle={NavShape} /></div>:null}
            {matches1200?null:<div className="EmptyContainerBis" />}
        </header>
    )
};
export default HeaderV2