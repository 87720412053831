import TopSectionShared from "../../components/Shared/TopSectionShared";
import "../../styles/pages/CarnetsV2.scss";
import TimelineSection from "../../components/SectionCarnets/TimelineSection";
import background from "../../assets/images/Carnets/BG.webp";
import ContactSection from "../../components/SectionHome/ContactSection";
import {t} from "i18next";

export default function CarnetsV2() {
    return (
        <main id="CarnetsV2">
            <div id="timeline-absolute">
                <div id="timeline-absolute-top"></div>
            </div>
            <div id="timeline-absolute-background">

            </div>

            <TopSectionShared
                backgroundImage={background}
                titre={t('logBooks.title')}
                sousTitre={t('logBooks.subtitle')}
            />
            <div id="timeline-absolute-top"></div>
            <TimelineSection />
            <div id="timeline-absolute-bottom"></div>
            <ContactSection/>
        </main>
    );
}