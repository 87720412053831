import "../../styles/components/footer.scss"
import { FaGithub } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { MdMail } from "react-icons/md";



const Footer = () => {

    return(
        <footer id="footer-conatainer">
            <div id="link-footer-container">
                 contact  •
                <a href="mailto:cordillerice@proton.me"><MdMail className="fafooter" /></a>
                <a href="https://www.instagram.com/Cordillerice/"><AiFillInstagram className="fafooter" /></a>
                • made by •
                <a href="https://github.com/ach-ber"><FaGithub className="fafooter" /></a>
            </div>
            <div id="link-git-container"></div>
        </footer>
    )
}

export default Footer