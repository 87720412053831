import React, { useState } from 'react';
import {Skeleton} from "@mui/material";


/*

{loading===0? (<div>{loading}{props.imageUrl}<Skeleton variant="rectangular" style={{width:"100%"}} className={props.imageClass} /><img  src={props.imageUrl} style={{display:"none"}}  className={props.imageClass} onLoad={handleImageLoaded}/> </div> )
            : (<img  src={props.imageUrl}  className={props.imageClass} onLoad={handleImageLoaded}/>)
        }

 */

function ImageSkeleton(props) {
    const [loading, setLoading] = useState(true);

    const handleImageLoaded = () => {
        setTimeout(() => {
            setLoading(false);
        }
        , 400);

    };

    return (<>

        {loading && <Skeleton variant="rectangular" style={props.skeletonStyle} className={props.imageClass} />}<img  src={props.imageUrl}  className={props.imageClass} onLoad={handleImageLoaded}/>

    </>);
}

export default ImageSkeleton;
