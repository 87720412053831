
import "../../styles/components/ContactTransition.scss";
import {Link} from "react-router-dom";
import {t} from "i18next";
function ContactTransition() {

    return (
        <section className={"container-contact-transition"}>
            <h2>{t('home.ContactSection.title')}</h2>
            <h3>{t('home.ContactSection.subtitle')}</h3>
            <div className={"contact-transition"}>
                <Link to="/Contact" className="contact-transition-link">{t('home.ContactSection.link1')}</Link>
                <a className="contact-transition-link" href={"https://www.instagram.com/Cordillerice/"}>{t('home.ContactSection.link2')}</a>
            </div>
        </section>
    );
}
export default ContactTransition;